/* ------------------------------------------------------------
Type
------------------------------------------------------------ */

h1 {
    font-size:42px;

    @include respond-to('medium') {
        font-size:52px;
    }
}

h2 {
  font-size:34px;

  @include respond-to('medium') {
    font-size:46px;
  }
}

h3 {
  font-weight:normal;
  font-size:26px;

  @include respond-to('medium') {
    font-size:32px;
  }
}

:focus {
  outline:none;
}
