/**
 * Cookie Consent Styling
 */

#cookies-policy.cookies {
    bottom: 0;
    font-size: 16px;
    max-height: 100%;
    max-width: 100%;
    overflow: auto;
    position: fixed;
    right: 0;
    transition: transform .2s ease-out, opacity .2s ease-out;
    z-index: 9999;
}

#cookies-policy.cookies--closing {
    opacity: 0;
    transform: translateY(10px);
}

#cookies-policy.cookies--show .cookies__btn--customize {
    border-bottom: 1px solid $brand-primary;
    border-top: none;
}

#cookies-policy.cookies--show .cookies__btn svg {
    transform: rotate(180deg);
}

#cookies-policy.cookies--no-js .cookies__expandable:target, #cookies-policy.cookies--no-js .cookies__section .cookies__expandable {
    height: auto;
    opacity: 1;
    transition: height .3s ease-out, opacity .3s ease-out;
    visibility: visible;
}

#cookies-policy.cookies--no-js .cookies__details {
    display: none;
}

#cookies-policy.cookies--no-js .cookies__sections {
    max-height: fit-content;
}

#cookies-policy.cookies--no-js svg {
    display: none;
}

#cookies-policy .cookies__expandable {
    display: block;
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: height .3s ease-out, opacity .3s ease-out, visibility 0s linear .3s;
    visibility: hidden;
}

#cookies-policy .cookies__expandable--open {
    height: auto;
    opacity: 1;
    transition: height .3s ease-out, opacity .3s ease-out;
    visibility: visible;
}

#cookies-policy .cookies__alert {
    background: $brand-primary-light;
    border: 1px solid $brand-primary;
    border-radius: 8px;
    box-shadow: 0 12px 12px -8px rgba(0, 0, 0, .08), 0 0 4px rgba(0, 0, 0, .04);
    margin: 4%;
    max-height: 90%;
    max-width: 90%;
    width: 22em;
}

#cookies-policy .cookies__container {
    display: block;
    height: auto;
    opacity: 1;
    overflow: hidden;
    transition: height .3s ease-out, opacity .3s ease-out, visibility 0s linear .1s;
    visibility: visible;
}

#cookies-policy .cookies__container--hide {
    height: 0;
    opacity: 0;
    transition: height .3s ease-out, opacity .3s ease-out, visibility 0s linear .3s;
    visibility: hidden;
}

#cookies-policy .cookies__wrapper {
    padding: 1em 1.5em;
}

#cookies-policy .cookies__title {
    color: $gray-darker;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.4em;
    margin-bottom: .8em;
}

#cookies-policy .cookies__intro {
    color: $gray-darker;
    font-size: .875em;
    font-weight: 450;
    line-height: 1.4em;
}

#cookies-policy .cookies__intro p {
    margin-top: 1em;
}

#cookies-policy .cookies__intro p:first-child {
    margin-top: 0;
}

#cookies-policy .cookies__intro a {
    color: inherit;
    text-decoration: underline;
    transition: color .2s ease-out;
}

#cookies-policy .cookies__intro a:focus, #cookies-policy .cookies__intro a:hover {
    color: $gray-darker;
}

#cookies-policy .cookies__actions {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: space-between;
    margin-top: 1em;
}

#cookies-policy .cookies__btn--customize {
    align-items: center;
    border-top: 1px solid $brand-primary;
    color: $gray-darker;
    display: flex;
    font-size: .875em;
    font-weight: 600;
    justify-content: space-between;
    line-height: 1em;
    padding: 1.125em 24px;
    position: relative;
    text-decoration: none;
}

#cookies-policy .cookies__btn svg {
    transition: transform .2s ease-out;
}

#cookies-policy .cookies__sections {
    max-height: 450px;
    overflow-y: scroll;
}

#cookies-policy .cookies__section {
    padding: 0 1.5em;
}

#cookies-policy .cookies__section + .cookies__section {
    border-top: 1px solid $brand-primary;
}

#cookies-policy .cookies__box, #cookies-policy .cookies__category {
    display: block;
    overflow: hidden;
    position: relative;
}

#cookies-policy .cookies__category input {
    display: block;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 105%;
    top: 0;
}

#cookies-policy .cookies__box {
    cursor: pointer;
    line-height: 1.4em;
    padding: 1em 3em 1em 0;
}

#cookies-policy .cookies__box:after, #cookies-policy .cookies__box:before {
    border-radius: 1.4em;
    content: "";
    display: block;
    position: absolute;
    top: 50%;
}

#cookies-policy .cookies__box:after {
    background: $gray-lighter;
    height: 1.4em;
    margin-top: -.7em;
    right: 0;
    transition: background .2s ease-out, opacity .2s ease-out;
    width: 2.5em;
    z-index: 0;
}

#cookies-policy .cookies__box:before {
    background: $white;
    height: 1em;
    margin-top: -.5em;
    right: .75em;
    transform: translateX(-.55em);
    transition: transform .2s ease-out;
    width: 1em;
    z-index: 1;
}

#cookies-policy .cookies__category input:checked + .cookies__box:after {
    background: $brand-primary;
    opacity: 1;
}

#cookies-policy .cookies__category input:disabled + .cookies__box:after {
    opacity: .6;
}

#cookies-policy .cookies__category input:checked + .cookies__box:before {
    transform: translateX(.55em);
}

#cookies-policy .cookies__label {
    color: $gray-darker;
    font-size: .875em;
    font-weight: 600;
}

#cookies-policy .cookies__info {
    color: $gray-darker;
    font-size: .875em;
    font-weight: 450;
    line-height: 1.4em;
}

#cookies-policy .cookies__details {
    color: $gray-darker;
    display: block;
    font-size: .875em;
    margin: .625em 0 .9em;
    transition: color .2s ease-out;
}

#cookies-policy .cookies__details:focus, #cookies-policy .cookies__details:hover {
    color: $gray-darker;
}

#cookies-policy .cookies__definitions {
    color: $gray-darker;
    font-size: .875em;
    line-height: 1.2em;
    padding-top: .8em;
}

#cookies-policy .cookies__cookie + .cookies__cookie {
    margin-top: 1em;
}

#cookies-policy .cookies__name {
    color: $gray-darker;
    display: inline;
    font-weight: 600;
    line-height: 22px;
}

#cookies-policy .cookies__duration {
    color: $gray-darker;
    display: inline;
    text-align: right;
}

#cookies-policy .cookies__description {
    color: $gray-darker;
    display: block;
    line-height: 120%;
    padding-top: .3em;
    text-align: left;
    width: 100%;
}

#cookies-policy .cookies__save {
    border-top: 1px solid $brand-primary;
    display: flex;
    justify-content: flex-end;
    margin-top: .4em;
    padding: .75em 1.5em;
}

#cookies-policy .cookiesBtn {
    width: 100%;
}

#cookies-policy .cookiesBtn__link {
    background: $brand-primary;
    border: 1px solid $brand-primary;
    border-radius: 4px;
    color: $white;
    cursor: pointer;
    display: block;
    font: inherit;
    font-size: 14px;
    font-weight: 600;
    line-height: 1em;
    margin: 0;
    overflow: hidden;
    padding: .86em 1em;
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    transition: opacity .2s ease-out;
    white-space: nowrap;
    width: 100%;
}

#cookies-policy .cookiesBtn--essentials .cookiesBtn__link {
    background-color: $gray-light;
    border-color: $gray-light;
    color: $gray-darker;
}

#cookies-policy .cookiesBtn__link:focus, #cookies-policy .cookiesBtn__link:hover {
    opacity: .8;
}
