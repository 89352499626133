/* ------------------------------------------------------------
Mixins
------------------------------------------------------------ */

@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. '
         + 'Please make sure it is defined in `$breakpoints` map.';
  }
}



/* Cover afbeelding */
@mixin background-cover {
  background-repeat:no-repeat;
    background-position:center center;
  -webkit-background-size: cover;
     -moz-background-size: cover;
       -o-background-size: cover;
        background-size: cover;
}

/* Retina images */
@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: $width $height;
  }
}

/* Tekst Afkappen */
@mixin text-cutoff {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* Transitions */
@mixin transition-all {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@mixin transition-height {
  -webkit-transition: max-height 0.3s ease-in-out;
  -moz-transition: max-height 0.3s ease-in-out;
  -o-transition: max-height 0.3s ease-in-out;
  transition: max-height 0.3s ease-in-out;
}

@mixin transition-transform {
  -webkit-transition: transform 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
}

@mixin transition-opacity {
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

@mixin transition-color {
  -webkit-transition: color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

@mixin transition-border-color {
  -webkit-transition: border-color 0.3s ease-in-out;
  -moz-transition: border-color 0.3s ease-in-out;
  -o-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
}

@mixin transition-background-color {
  -webkit-transition: background-color 0.3s ease-in-out;
  -moz-transition: background-color 0.3s ease-in-out;
  -o-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}



/* Transform */
@mixin transform-turn {
  -ms-transform: rotate(180deg); /* IE 9 */
  -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
  transform: rotate(180deg);
}
