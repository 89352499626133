/* ------------------------------------------------------------
Nav
------------------------------------------------------------ */

.navbar-default {
    margin-bottom: 0;
    min-height: 50px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    background-color: #fff;
    border: 0;
    border-radius: 0;

    @include respond-to('medium') {
        padding-top: 15px;
        padding-bottom: 15px;
        background-color: #fff;
        -webkit-transition: opacity 250ms ease-in-out, -webkit-transform 250ms ease-in-out;
        transition: opacity 250ms ease-in-out, -webkit-transform 250ms ease-in-out;
        transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
        transition: transform 250ms ease-in-out, opacity 250ms ease-in-out, -webkit-transform 250ms ease-in-out;
    }

    .navbar-header {
        margin: 0;

        .navbar-brand {
            margin: 6px 0 0 0;
            width: 41px;
            height: 37px;
            overflow: hidden;
            padding: 0;
            @include respond-to('medium') {
                margin: 0;
                width: 51px;
                height: 47px;
            }
            img {
                width: 100%;
                height: 100%;
            }
        }

        .navbar-toggle {
            padding: 0;
            margin: 15px 0px 0 0;
            border: 0;
            border-radius: 0;
        }

        .navbar-toggle:hover,
        .navbar-toggle:focus {
            background-color: transparent;
        }
    }

    .nav-icon {
        width: 30px;
        height: 21px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;

        span {
            display: block;
            position: absolute;
            height: 2px;
            width: 100%;
            background: #000;
            border-radius: 2px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;
        }

        span:nth-child(1) {
            top: 0px;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
        }

        span:nth-child(2) {
            top: 9px;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
        }

        span:nth-child(3) {
            top: 18px;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
        }
    }

    .nav-icon.open {
        span:nth-child(1) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
            top: -3px;
            left: 8px;
        }

        span:nth-child(2) {
            width: 0%;
            opacity: 0;
        }

        span:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
            top: 18px;
            left: 8px;
        }
    }

    .navbar-collapse {
        border: 0;
    }

    .navbar-nav {
        margin: 0px -15px;
        margin-top: 20px;

        @include respond-to('medium') {
            margin-top: 0;
        }

        li:not(.highlight) {
            font-size: 16px;
            color: #fff;
            font-weight: 500;
            border-top: 1px solid #eeeeee;

            @include respond-to('medium') {
                border-top: 0;
            }

            a {
                color: $gray-base;
                padding: 15px 15px;
                transition: color 0.2s ease;

                @include respond-to('medium') {
                    padding: 9px 14px;
                    color: #717171;
                }
            }
            &.active a {
                color: $brand-primary;
            }
        }

        li.highlight {
            @include respond-to('medium') {
                margin-left: 14px;
                font-size: 11px;
                font-weight: 800;
                text-transform: uppercase;
                letter-spacing: 1px;
            }
            a {
                background-color: $brand-primary;
                color: $white;
                padding-left: 28px;
                padding-top: 15px;
                padding-bottom: 15px;
                font-size: 12px;
                letter-spacing: 1.79px;
                line-height: 1.3333333;
                &:hover {
                    background-color: $brand-primary-dark;
                }
            }
        }
    }

    // Dropdown menu styling
    @include respond-to('medium') {
        .dropdown.open .dropdown-menu {
            visibility: visible;
            -webkit-transform: scale(1) translateY(11px);
            transform: scale(1) translateY(11px);
            opacity: 1;
        }

        .dropdown-menu {
            padding: 0;
            float: left;
            width: 100%;
            margin-bottom: 20px;
            margin-top: -15px !important;

            @include respond-to('medium') {
                box-shadow: 0 0px 2px rgba(0, 0, 0, .1), 0 6px 4px rgba(0, 0, 0, .03);
                font-size: inherit;
                text-align: right;
                border-radius: 3px !important;
                padding: .8rem 0;
                color: inherit;
                border: none;
                left: auto;
                margin: 0 !important;
                right: 0;
                width: auto;
                -webkit-transition: all .15s ease;
                transition: all .15s ease;
                visibility: hidden;
                -webkit-transform: scale(.7) translateY(-20px);
                transform: scale(.7) translateY(-20px);
                display: block;
                opacity: 0;
            }

            li {
                border: 0 !important;

                a {
                    padding: 10px 20px !important;
                    color: #fff !important;
                    font-weight: 500;
                    font-size: 0.8rem;
                    transition: background-color 0.2s ease;

                    @include respond-to('medium') {
                        padding: 6px 20px !important;
                        color: $gray-base !important;
                        text-transform: initial;
                        font-size: 0.8rem;
                        &:hover, &:focus, &:active {
                            color: $gray-darker !important;
                        }
                    }
                }
            }

            li.active a {
                background-color: #F5F5F5;
            }
        }

        .dropdown-menu:before {
            @include respond-to('medium') {
                content: "";
                position: absolute;
                top: -4px;
                right: 11px;
                margin: 0 0 0 -6px;
                width: 8px;
                height: 8px;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                border-radius: 2px 0 0 0;
                background: #fff;
                box-shadow: -3px -3px 5px rgba(82, 95, 127, .1);
            }
        }
    }
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
    background-color: transparent;
    color: $gray-darker;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
    background-color: transparent;
    color: $brand-primary;
}

/*
  .navbar-default .navbar-nav > li > a:hover,
  .navbar-default .navbar-nav > li > a:focus {
      background-color: transparent;
      color: #DCDCDC;
  }
*/


.navbar-default.mod-transparent {
    @include respond-to('medium') {
        background-color: transparent;
    }
}

.navbar-default.is-hidden {
    @include respond-to('medium') {
        opacity: 0;
        -webkit-webkit-transform: translateY(-100%) translateZ(0) translate3d(0, 0, 0);
        transform: translateY(-100%) translateZ(0) translate3d(0, 0, 0);
    }
}

.navbar-default.home .navbar-nav {
    li {
        a {
            @include respond-to('medium') {
                color: #fff;

            }
        }
        &.active a {
            @include respond-to('medium') {
                color: $brand-primary;
            }
        }
    }
    > .open {
        > a, > a:focus, > a:hover {
            color: $white;
        }
    }
}

.navbar-default.home.sticky-style,
.navbar-default.sticky-style {
    @include respond-to('medium') {
        ul.navbar-nav {
            li:not(.highlight) {
                a {
                    color: $gray-base;
                }

                a:focus,
                a:hover {

                    color: $gray-darker;
                }
                &.active a {
                    color: $brand-primary;
                }
            }

            li.highlight {
                a {
                    color: #fff;
                }
            }
        }
    }
}










