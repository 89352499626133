/* ------------------------------------------------------------
Header
------------------------------------------------------------ */

.slider-section, .page-header-section {
    padding-top: 40px;
    position: relative;
    width: 100%;
    height: 340px;
    background-color: $white;
    background: $white url('../img/header_pattern.png') no-repeat right center / 855px 340px;
    @include image-2x("../img/header_pattern@2x.png", 855px, 340px);

    @include respond-to('medium') {
        padding-top: 20px;
    }

    .cover_img {
        position: absolute;
        width: 100%;
        height: 100%;
        @include background-cover;
        opacity: 0.57;
    }

    .container,
    .row,
    .col-xs-12,
    .slider-slide,
    .slick-list,
    .slick-track {
        height: 100%;
    }

    .slick-slide {
        position: relative;
    }

    .col-xs-12 {
        display: table;
    }

    .v_middle {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        user-select: none;

        h2,
        p {
            color: $gray-darker;
        }

        h2 {
            margin-bottom: 20px;
        }

        p {
            font-size: 17px;
            color: $gray-base;
            max-width: 570px;
            margin: 0 auto;
            @include respond-to('medium') {
                font-size: 20px;
            }

            a {
                color: $gray-base;
            }

            a.active {
                color: $brand-primary;
                font-weight: bold;
            }

            a:hover,
            a:focus {
                text-decoration: none;
            }
        }

        .btn {
            margin-top: 15px;
            @include respond-to('medium') {
                margin-top: 30px;
            }
        }
    }

    .go_down {
        margin-left: -50px;
        width: fit-content;
        height: fit-content;
        cursor: pointer;
        position: absolute;
        bottom: 15px;
        left: 50%;
        text-align: center;
        @include transition-all;
        display: block;

        span {
            font-size: 18px;
            font-weight: bold;
            color: #fff;
            white-space: nowrap;
            display: block;
        }

        i {
            color: #fff;
        }
    }

    .go_down:hover,
    .go_down:focus {
        bottom: 20px;
    }
}

.slider-section {
    background-image: none;
    background-color: $black;
    height: 450px;
    position: relative;

    @include respond-to('medium') {
        padding-top: 0;
        height: 730px;
    }

    .v_middle {
        text-align: left;

        h2,
        p {
            color: #fff;
            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5)
        }

        p {
            color: #EFEFEF;
        }
    }
}
