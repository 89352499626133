/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */


/* ------------------------------------------------------------
   Core variables
   ------------------------------------------------------------ */
//@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "variables";

/* ------------------------------------------------------------
   Core mixins
   ------------------------------------------------------------ */
@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/mixins";

/* ------------------------------------------------------------
   Reset and dependencies
   ------------------------------------------------------------ */
@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
//@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/print";
//@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";

/* ------------------------------------------------------------
   Core css
   ------------------------------------------------------------ */
@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/type";
//@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/grid";
//@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/buttons";


/* ------------------------------------------------------------
   Components
   ------------------------------------------------------------ */

@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
//@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
//@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/navs";
@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/navbar";
//@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";
//@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/pagination";
//@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/pager";
//@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/labels";
//@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/badges";
//@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/jumbotron";
//@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/thumbnails";
//@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/alerts";
//@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/progress-bars";
//@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/media";
//@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/list-group";
//@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/panels";
//@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed";
//@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/wells";
//@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/close";

/* ------------------------------------------------------------
   Components w/ Javascript
   ------------------------------------------------------------ */
//@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/modals";
//@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/tooltip";
//@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/popovers";
//@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/carousel";

@import "../../vendor/font-awesome/css/font-awesome";
@import "../../font/ss-glyphish-outlined.css";
@import "../../font/stylesheet.css";
@import "../../vendor/aos/dist/aos.css";
@import "../../vendor/slick-carousel/slick/slick.scss";
@import "../../vendor/slick-carousel/slick/slick-theme.scss";
@import "../../vendor/slick-lightbox/dist/slick-lightbox.css";
@import '~@cmyee/pushy/css/pushy.css';

/* ------------------------------------------------------------
   Utility classes
   ------------------------------------------------------------ */
@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "../../vendor/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";

/* ------------------------------------------------------------
   Custom styles
   ------------------------------------------------------------ */
@import "mixins";
@import "type";
@import "nav";
@import "header";
@import "footer";
@import "forms";
@import "base";
@import "cookies";
@import "custom";
