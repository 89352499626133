/* ------------------------------------------------------------
Footer
------------------------------------------------------------ */

footer {
    padding:50px 0 25px;
    background-color:#202020;

    @include respond-to('medium') {
        padding:100px 0 50px;
    }

    h6 {
        color:#fff;
        text-transform:uppercase;
        margin-bottom:20px;
    }

    ul {
        margin-bottom:10px;
        padding:0;
        list-style-type: none;
        li {
            color:#B3B3B3;
            font-size: 16px;
            line-height: 27px;
            a{
                color:#B3B3B3;
            }

            a:hover,
            a:focus {
                color:#fff;
                text-decoration: none;
            }
        }
    }

    .copy {
        margin-top:10px;
        hr {
            border-color:#4C4C4C;
        }

        p {
            margin:0;
            font-size:14px;
            color:#B3B3B3;
        }

        p.right {
            float:left;
            @include respond-to('medium') {
                float:right;
            }
            a{
                color:#fff;
                font-weight:500;
            }
        }
    }
}
