/* ------------------------------------------------------------
Base
------------------------------------------------------------ */

html {
    min-height: 100vh;
    font-size: 18px;
}

body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

body {
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    font-size: 18px;
}

.center {
    text-align: center;
}

.btn {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.79px;
    @include transition-all;
    position: relative;
}


.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("../../vendor/slick-carousel/slick/ajax-loader.gif") center no-repeat;
    }
}

@if $slick-font-family == "slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("../../vendor/slick-carousel/slick/fonts/slick.eot");
        src: slick-font-url("../../vendor/slick-carousel/slick/fonts/slick.eot?#iefix") format("embedded-opentype"), slick-font-url("../../vendor/slick-carousel/slick/fonts/slick.woff") format("woff"), slick-font-url("../../vendor/slick-carousel/slick/fonts/slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}

.slick-dots li button:before {
    color: #fff;
    opacity: 1;
}

.slick-dots li.slick-active button:before {
    color: $brand-primary;
    opacity: 1;
}

.slick-dots li {
    height: 15px;
    width: 15px;
    margin: 0 2px;
}


.btn.btn-md {
    padding-right: 48px;
    font-size: 11px;
    letter-spacing: 1px;
}

.btn.btn-md span {
    position: absolute;
    right: -1px;
    top: -1px;
    width: 30px;
    height: 35px;
    line-height: 42px;
    background-color: $brand-primary-dark;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.btn.btn-md span .fa {
    color: #fff;
    font-size: 20px;
}


.btn.btn-lg {
    padding-right: 68px;
    font-size: 12px;
    letter-spacing: 1.79px;
}

.btn.btn-lg span {
    position: absolute;
    right: -1px;
    top: -1px;
    width: 40px;
    height: 48px;
    line-height: 52px;
    background-color: $brand-primary-dark;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.btn.btn-lg span .fa {
    color: #fff;
    font-size: 20px;
}


/* Services */
.widget_services {
    padding: 50px 0;
    position: relative;
    text-align: center;

    @include respond-to('medium') {
        padding: 100px 0;
    }

    .service_wrap {
        margin-bottom: 15px;
        padding: 40px 25px;
        background-color: #fff;
        border-radius: 3px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);

        @include respond-to('medium') {
            margin-bottom: 0;
        }

        .circle {
            padding: 25px 0;
            margin-bottom: 25px;
            display: inline-block;
            width: 100px;
            height: 100px;
            border: 2px solid #F2F2F4;
            border-radius: 100%;

            .icon_floor {
                margin-top: 3px;
                width: 40px;
                height: 40px;
                display: inline-block;
                background: url('../img/icons/service_floor_80x80.png') center center / 40px 40px;
            }

            i {
                font-size: 40px;
                color: $brand-primary;
            }

        }

        h4 {
            font-weight: 500;
            margin-bottom: 10px;
        }

        p {
            line-height: 22px;

        }

        .btn {
            margin-top: 25px;
        }

    }
}


/* Vendura */
.widget_content_img {
    position: relative;
    width: 100%;

    @include respond-to('medium') {
        min-height: 500px;
    }

    .container,
    .row,
    .col-xs-12 {
        height: 100%;
    }

    .col-xs-12 {
        display: table;

    }

    .v_middle {
        .btn {
            margin-top: 20px;
        }
    }

    .img_wrap {
        position: absolute;
        width: 100%;
        height: 250px;
        @include background-cover;

        @include respond-to('large') {
            height: 100%;
            width: 50%;
        }
    }
}

.widget_content_img.right {
    .v_middle {
        @include respond-to('medium') {
            padding-right: 100px;
        }
    }

    .img_wrap {
        right: 0;
    }
}

.widget_content_img.left {
    .v_middle {
        @include respond-to('medium') {
            padding-left: 100px;
        }
    }

    .img_wrap {
        left: 0;
    }
}


/* Kwalitie */
.widget_usp {
    padding: 50px 0;
    @include respond-to('medium') {
        padding: 100px 0;
        background-position: 45% center;
    }

    h3 {
        text-align: center;
    }

    .subline {
        margin: 15px 0 10px 0;
        text-align: center;
        font-size: 18px;
    }

    .usp_wrap {
        margin: 40px auto 0 auto;
        padding-left: 80px;
        padding-top: 3px;
        position: relative;
        width: 100%;
        max-width: 350px;

        .circle {
            padding-top: 12px;
            position: absolute;
            left: 0;
            top: 0;
            background-color: #fff;
            width: 65px;
            height: 65px;
            border-radius: 50%;
            border: 2px solid #F2F2F4;
            text-align: center;

            i {
                font-size: 32px;
                color: $brand-primary;
            }
        }

        h4 {
            margin-bottom: 6px;
            font-size: 18px;
        }

        p {
            font-size: 15px;
            line-height: 22px;
        }
    }

    .btn {
        margin-top: 35px;
    }
}

/* Content */
.widget_content {
    padding: 50px 0;
    text-align: left;

    @include respond-to('medium') {
        padding: 100px 0;
    }

    h4 {
        margin-bottom: 20px;
    }
}

/* Quote */
.widget_quote {
    position: relative;
    padding: 80px 0 50px 0;
    background-color: #000;
    text-align: center;

    @include respond-to('medium') {
        padding: 150px 0 120px 0;
    }


    .cover_img {
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        @include background-cover;
        opacity: 0.22;
    }


    quote {
        font-size: 18px;
        color: #fff;
        text-align: center;
        max-width: 500px;
        display: inline-block;

        @include respond-to('medium') {
            font-size: 24px;
        }
    }

    quote:before {
        margin-left: -10px;
        position: absolute;
        left: 50%;
        top: -70px;
        content: '“';
        font-size: 72px;
    }
}


/* Widget more */
.widget_more {
    padding: 50px 0;
    text-align: left;
    background-color: #F9FAFA;

    @include respond-to('medium') {
        padding: 100px 0;
    }

    h4 {
        margin-bottom: 40px;
    }

    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            line-height: 32px;

            a {
                color: #717171;
            }
        }
    }
}

/* Extra projects / services */
.widget_extra.single {
    height: 300px;
}

.widget_extra {
    text-align: center;
    background-color: #000;
    position: relative;
    height: 600px;

    @include respond-to('medium') {
        padding: 0;
        height: 300px;
    }

    .col-xs-12 {
        display: block;
    }

    .v_middle {
        display: table-cell;
        vertical-align: middle;
    }

    h4 {
        margin-bottom: 20px;
        color: #fff;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    }

    .subline {
        margin-bottom: 5px;
        color: #fff;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    }

    .ss-lightbulb,
    .ss-binoculars {
        font-size: 24px;
        color: #fff;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    }

    .services_wrap {
        top: 0;
        height: 300px;
        width: 100%;
        position: absolute;
        @include background-cover;
        opacity: 0.57;
        @include respond-to('medium') {

        }
    }

    .services_wrap.left {
        left: 0;
        @include respond-to('medium') {
            width: 50%;
        }
    }

    .services_wrap.right {
        right: 0;
        top: 300px;
        @include respond-to('medium') {
            top: 0;
            width: 50%;
        }
    }
}


/* Referenties */
.reference_overview {
    position: relative;

    .filters {
        background-color: #F7F7F7;

        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;
            text-align: center;

            li {
                display: inline-block;
                color: $gray-base;


                span {
                    padding: 10px;
                    display: inline-block;
                    @include respond-to('medium') {
                        padding: 20px 15px;
                    }
                }

                a {
                    padding: 10px;
                    display: inline-block;
                    color: $gray-base;
                    @include transition-background-color;
                    @include respond-to('medium') {
                        padding: 20px 15px;
                    }
                }
            }

            li.active {
                a {
                    color: $white;
                    background-color: $brand-primary;
                }
                &:hover a, &:focus a {
                    color: $white;
                    background-color: $brand-primary;
                    text-decoration: none !important;
                }
            }

            li:hover a,
            li:focus a {
                text-decoration: none !important;
                background-color: $gray-light;
            }

        }
    }

    a .reference_wrap:hover .img_wrap,
    a .reference_wrap:focus .img_wrap {
        transform: scale(1.02);
    }

    .reference_wrap {
        width: 100%;
        float: left;
        position: relative;
        overflow: hidden;
        transition: all 0.2s ease;
        .img_wrap {
            filter: brightness(0.9) saturate(0.7);
            float: left;
            width: 100%;
            height: 300px;
            @include background-cover;
            @include transition-all;
            @include respond-to('small') {
                height: 400px;
            }
            @include respond-to('medium') {
                height: 350px;
            }
            @include respond-to('large') {
                height: 400px;
            }
        }
        .caption {
            padding: 140px 20px 25px 20px;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
            color: $white;
            transition: inherit;
            white-space: nowrap;
            overflow: hidden;
            .categorie {
                color: inherit;
                font-size: 14px;
                text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
                transition: inherit;
                width: fit-content;
                max-width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            h3 {
                color: inherit;
                font-size: 20px;
                @include respond-to('large') {
                    font-size: 22px;
                }
                text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
                transition: inherit;
                width: fit-content;
                max-width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
        &:hover {
            .img_wrap {
                filter: brightness(1.06) saturate(1.1);
            }
            .caption {
                color: $brand-primary-dark;
                .categorie {
                    font-size: 18px;
                }
                h3 {
                    font-size: 26px;
                    @include respond-to('large') {
                        font-size: 30px;
                    }
                }
            }
        }
    }
}


/* Vendura */
.contact_info {
    position: relative;
    width: 100%;

    @include respond-to('medium') {
        min-height: 370px;
    }

    .container,
    .row,
    .col-xs-12 {
        height: 100%;
    }

    .col-xs-12 {
        display: table;

    }

    .v_middle {
        padding-top: 300px;
        padding-bottom: 50px;

        @include respond-to('medium') {
            display: table-cell;
            vertical-align: middle;
            padding-top: 0;
            padding-bottom: 0;
        }

        h3 {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 25px;
        }

        ul {
            float: left;
            width: 100%;
            list-style-type: none;
            padding-left: 0;

            @include respond-to('small') {
                width: 50%;
            }

            @include respond-to('medium') {
                width: 270px;
            }

            li {
                line-height: 32px;
            }
        }
    }

    .gmaps {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 330px;
        @include background-cover;

        @include respond-to('medium') {
            height: 100%;
            width: 50%;
        }

        #map-canvas {
            width: 100%;
            height: 100%;
        }
    }
}

.contact_form {
    padding: 50px 0;
    text-align: center;

    @include respond-to('medium') {
        padding: 100px 0;
    }

    input {
        padding: 0 15px;
        margin-bottom: 20px;
        height: 60px;
        width: 100%;
        background-color: #fff;
        border: 1px solid #EAEAEA;
        border-radius: 8px;
        transition: all 0.2s ease;
        &:focus {
            border-color: transparentize($brand-primary, 0.4);
            box-shadow: 0 0 4px transparentize($brand-primary, 0.5);
        }
    }

    textarea {
        padding: 15px;
        margin-bottom: 20px;
        height: 120px;
        width: 100%;
        background-color: #fff;
        border: 1px solid #EAEAEA;
        border-radius: 8px;
        resize: none;
        &:focus {
            border-color: transparentize($brand-primary, 0.4);
            box-shadow: 0 0 4px transparentize($brand-primary, 0.5);
        }
    }

    .has-error {
        input, textarea {
            border-color: #dcadab;
            &:focus {
                border-color: darken(#dcadab, 10%);
                box-shadow: 0 0 4px transparentize(#dcadab, 0.5);
            }
        }
    }


}









